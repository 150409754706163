.headerImageSlider{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.logoImg{
    margin-top: 5%;
    display: block;
    /* 
    margin-left: auto;
    margin-right: auto;  
    */
    /* margin-left: 10%; */
    transition: all 0.5s; 
    opacity: 0.9;

}
.p00{
    font-family: "Montserrat";
    color: white;
    font-size: 40px;    
    font-weight: 300;
    /* text-align: center;    */
     transition: all 0.5s;
 margin-top: 0%;
 letter-spacing: 2px;

 }
.p2{
    font-family: "Kalnia", serif;
    font-weight: 400;
    color: #60cda5;
    /* text-align: center;     */
     transition: all 0.5s;
     width: 300px;
     letter-spacing: 2px;
     line-height: 1;


}
.header{
    padding:1% 0% 15% 0%;
    margin: auto;
    width: 70%;    transition: all 0.5s;

} .iconsHeader{
    float:right;
     position:absolute;
      width:37%; transition: all 0.5s;
  }
  .pBlack{
    font-family: "Montserrat";
    font-size: 45px;
    color: black;
     text-align: center;
}
   @media (min-width: 3000px) {
    .logoImg{
        height: 160px;
    }
    .p00{
        font-size: 45px;
      }
    .p2{
        font-size:35px;
    }.header{
        height:850px;}
}@media (min-width: 2200px) and (max-width: 3000px) {
    .logoImg{
        height: 160px;
    }
    .p00 , .pBlack{
        font-size: 45px;
      }
    .p2{
        font-size: 35px;
    }.header{
        height:650px;}
}
@media (min-width: 1500px) and (max-width: 2200px) {
    .logoImg{
        height: 140px;
    }
    .p00 , .pBlack{
        font-size: 40px;
      }
    .p2{
        font-size: 30px;
    }.header{
        height:550px;}
}
@media (min-width: 1200px) and (max-width: 1500px) {
    .logoImg{
        height: 120px;
    }
    .p00 , .pBlack{
        font-size: 40px;
      }
    .p2{
        font-size: 30px;
    }.header{
        height:500px;}
}
@media (min-width: 992px) and (max-width: 1200px) {
    .logoImg{
        height: 100px;
    }
    .p00 , .pBlack{
        font-size: 30px;
     }
    .p2{
        font-size: 25px;
    }.header{
        height:430px;}
}
@media (min-width: 768px) and (max-width: 992px) {
    .logoImg{
        height: 100px;
    }
    .p00 , .pBlack{
        font-size: 25px;
     }
    .p2{
        font-size: 20px;
    } .header{
        height:400px;}
}
@media (max-width: 768px) {
    .logoImg{
        height: 80px;
     }
    .p00 , .pBlack{
        font-size: 20px;
     }
    .p2{
        font-size: 15px;
    } .header{
        height:300px;}
}