@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courgette&family=Kalnia:wght@100..700&family=Playfair+Display+SC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');

/* width */
::-webkit-scrollbar {
    width: 20px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #000000; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #2e614e    ; 
  }

  ::-moz-selection { /* Code for Firefox */
    color: #ffffff;
    background: #2e614e;
  }
  
  ::selection {
    color: #ffffff;
    background: #2e614e;
  }

  a{
    text-decoration: none;
  }
  button{
    transition: all 0.5s;
  }
  .bodyContent{
    padding:8% 0px 8% 0px;
  }
  .bodyContentTexts{
    padding:8% 20px 8% 20px;

  }

  @media (max-width: 468px) {
    .bodyContentTextsLogo{
      padding:8% 25px 8% 0px;
  
    }
}