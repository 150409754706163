.ourProductsdiv, .ourProductsdivL, .ourProductsdivR{
  overflow:hidden;
  border: 1% ;
  border-color: #2e614e;
  border-style: solid;
  /* height: 400px; */
  margin: 20px;

}

.ourProductsdivL{
  border-radius: 15% 0;

}
.ourProductsdivR{
  border-radius: 0 15% ;

}

.ourProductsTitle {
  margin-left: -5px;
  padding: 20px 50px 0px 50px;
  font-size: 25px;
  font-family: "Montserrat";
  /* font-family: "Kalnia", serif; */
  width: 110%;
  display:inline-block;
  color:#ffffff;
  background-image: linear-gradient(to bottom right , #093927, #26a476);
  /* background-image: url("../../assets/texture.jpg"); */

}
.ourProductsImg{
    width:100%;
                height:auto;
                /* object-fit:cover; */
  
}
.PresidentImg {
  width: 300px;
  height: 300px; 
 padding: 10%;
 display: block;
 margin:0 auto;
}
.brandLogo {
  width: 70%;
  height: 60%; 
  margin-left: 60px;
}
#MyDiv {
  margin-left: -5px;
  padding: 20px 50px 0px 50px;
  font-size: 30px;
  font-family: "Montserrat";
  /* font-family: "Kalnia", serif; */
  width: 110%;
  display:inline-block;
  color:#ffffff;
  background-image: linear-gradient(to bottom right , #093927, #26a476);
  /* background-image: url("../../assets/texture.jpg"); */
}
#MyDiv2 {
  margin-left: -850px;
  margin-top: 0px;
  padding: 30px 150px 0px 130px;
  font-size: 30px;
  font-family: "Montserrat";
  /* font-family: "Kalnia", serif; */
  width: 170%;
  display:inline-block;
  color:#ffffff;
  background-image: linear-gradient(to bottom right , #093927, #26a476);
  /* background-image: url("../../assets/texture.jpg"); */
}
.OurProductsStyle{
  color:white;
  background-color: black;
  border-radius: 15% 0;
  width: 0%;
  border: 1% ;
  border-color: #2e614e;
  border-style: solid;
}
.OurProductsStyle2{
  color:white;
  background-color: black;
  border-radius: 0 15% ;
  width: 55%;
  height: 750px;
  border: 1% ;
  border-color: #2e614e;
  border-style: solid;
  rotate: 270deg;
}
.pattern{
  background-image: url("../../assets/pattern.png");
  background-size: 40px 40px;
  height: 50px;
  width: auto;
  background-size: 50px;

  background-color: #000000;
}

.patternw{
  background-image: url("../../assets/pattern.png");
  background-size: 40px 40px;
  height: 50px;
  width: auto;
  background-size: 50px;

  background-color: #ffffff;
}

.logo1img, .logo2img, .logo3img{
  width: 250px; height: auto;
  align-items: center;
  padding: 0% 10% 0% 10%;
  transition: all 0.5s;

}

.loglRow{
  margin-top: -6%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}
.logoCol{
  padding: 0% 4%;
}



#myBtn {
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: #221f20;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
  width: 60px;
  border-radius: 25% 0;

}

#myBtn:hover {
  background-color: #2e614e;
}


@media (min-width: 992px) and (max-width: 1200px) {
    
      .loglRow{
        margin-top: -60px;
      }
      .logo1img, .logo2img, .logo3img{
        width: 250px;
      
      }.logoCol{
        padding: 0% 1%;
      }
      .ourProductsTitle {
        font-size: 25px;
      }
}
@media (min-width: 768px) and (max-width: 992px) {
.PresidentImg {
    width: 400px;
    height: 400px;

  }
  .loglRow{
    margin-top: -60px;
  }
  .logo1img, .logo2img, .logo3img{
    width: 200px;
  
  }.logoCol{
    padding: 0% 1%;
  }
  .ourProductsTitle {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
    .PresidentImg {
      width: 250px;
      height: 250px;
      }
      .loglRow{
        margin-top: -60px;
      }
      .logo1img, .logo2img, .logo3img{
        width: 120px;
      
      }.logoCol{
        padding: 0% 1%;
      }
      .ourProductsTitle {
        font-size: 15px;
      }
    }

    
@media (max-width:568px) {
  .PresidentImg {
    width: 250px;
    height: 250px;
    }
    .loglRow{
      margin-top: -60px;
    }
    .logo1img, .logo2img, .logo3img{
      width: 80px;
    
    }.logoCol{
      padding: 0% 1%;
    }
  
#myBtn {
  position: fixed;
  bottom: 15px;
  right: 20px;
  z-index: 99;
  font-size: 1px;
  border: none;
  outline: none;
  background-color: #221f20;
  color: white;
  cursor: pointer;
  padding: 5px;
  border-radius: 4px;
  width: 30px;
  border-radius: 25% 0;

}
.ourProductsTitle {
  font-size: 15px;
}
  }

 