form{
    padding: 5px 15px;
margin:2% 20vw 5% 20vw;

}
label{
    font-family: "Montserrat";
    font-size: 20px;
    font-weight: bold;
       color: #2e614e; 
       margin: -1% 5%;
       padding-top: 20px;

}

input, textarea{
    border: 1px solid #2e614e; 
    width: 100%;
padding: 15px;
margin: 1% 5%;
}
input:hover{
    border: 1px solid #FFB536;

}
.sendButton{
    background-color: #2e614e;
    width: 100px;
 color: white;
font-size: 25px;
margin: 1% 5%;

}
.sendButton:hover{
    border: 1px solid #26a476; 
    background-color: #26a476;

}
.submitButton{
    border: 0px; 
    border-bottom: 1px solid #032A5E;
    background: #032A5E;
    font-family: "Montserrat";
    font-size: 20px;
    text-align: center;
    padding: 5px 15px;
    color:white;
    width: 200px;
    transition: all 0.5s;

}
.submitButton:hover{
    background: #304F78;
  font-size: 23px;

}
@media (max-width: 768px) {
    form{
    margin: 0 5%;
    }
}