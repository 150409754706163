.footer{
    padding: 100px 0% auto 0%;
    background-image: url("../../assets/footerBGImg.png");
    background-size: cover;
    /* background-repeat: no-repeat; */
}

  .navbarFooter {
    color: #60cda5;
    /* text-decoration: inherit; */
    font-family: "Montserrat";
    /* margin: 0px 0px 0px 30px; */
    font-size: 15px;
    text-align: center;
    padding: 5px 15px;
  }
  .navbarFooter:hover {
    color: #ffb536;
  }
  .footerText{
    color: #ffffff;
    font-family: "Montserrat";
    
  }


  .fotterNav{   
     /* float:left; */
    width: 90%;
     display: flex;
      justify-content: center; 
    padding:10px 7% 0px 0px;
  }

  .footerTitle{
    float:left; 
    padding:15px 0px 0px 5.5%; 
   }
  .cc{
    float:center;
    padding:15px 7% 50px 0px;
   }




  
@media (min-width: 1050px) {
  
  .fotterNav{   
      justify-content: right; 
   }

  .footerTitle{ 
    width: 40%;
   }
  .cc{  width: 100%;
   }
   .footerText{ 
    font-size: 16px;
    
  }
  .footerLogo{  
   padding:10px 0px 0px 5.5%;
       width: 10%;
  }
}
@media (min-width: 950px) and (max-width: 1050px) { 

  .fotterNav{   
        justify-content: right; 
   }

  .footerTitle{
        width: 40%;
  }
  .cc{
         width: 100%;
  }.footerText{ 
    font-size: 14px;
    
  }
  .navbarFooter{
    font-size: 12px;
    
  
}
.footerLogo{  
    float:left;
 padding:0px 0px 0px 5.5%;
     width: 10%;
}
}
@media (min-width: 750px) and (max-width: 950px) { 

  .fotterNav{    
    width: 100%;
      justify-content: center; 
    padding:30px 7% 0px 40px;
  }

  .footerTitle{
    float:center; 
       width: 100%;
  }
  .cc{
        width: 100%;
        
       text-align: justify ;
       text-align: center;
  }.footerText{ 
    font-size: 12px;
    
  }
  .navbarFooter{
text-align: center;  }
  
 
.footerLogo{   

  display: block;
  margin-left: auto;
  margin-right: auto; 
          width: 20%;
 }
 .footerTitle{
    /* padding:15px 0px 0px 5.5%;  */
      width: 100%;
      text-align: center;

  }
}@media (max-width: 750px)  { 

  .fotterNav{    
    width: 100%;
      justify-content: center; 
    padding:30px 7% 20px 40px;
  }

  .footerTitle{
    /* padding:15px 0px 0px 5.5%;  */
      width: 90%;
      text-align: center;

  }
  .cc{
    /* padding:15px 7% 30px 0px; */
       width: 100%;
       text-align: justify ;
       text-align: center;

  }.footerText{ 
    font-size: 12px;
       text-align: center;
  }
  .navbarFooter{
    text-align: center;
  }
  
   .footerLogo{   

    display: block;
    margin-left: auto;
    margin-right: auto; 
            width: 30%;
   }
}
