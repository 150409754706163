
.arrow {
    border: 1px solid #221f20; 
    background: #ffffff00;
    padding: 4%;
    border-radius: 25% 0;

}

.arrow:hover {
    border: 1px solid #2e614e;
    background: #2e614e;

}

.arrow:active {
    border: 1px solid #ffffff00;
}

.arrowStyleLeft {
    transform: scaleX(-1);
    
}
.arrowIcon{
    width:100%;
    height:100%;
    padding: 5px;
    fill:#221f20;
}
.arrowIcon:hover{
    width:100%;
    height:100%;
    padding: 3px;
    fill:#fff8fa;
}

@media (min-width: 1200px) {
    .arrow{
        width:60px;
        height:60px;
        margin-left: -5%;
        padding: 3% 4% 4% 4%;

    }.arrowStyleRight{
        margin-left: 90%;
    
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .arrow{
        width:50px;
        height:50px;
        margin-left: -5%;
        padding: 3% 4% 4% 4%;

    }.arrowStyleRight{
        margin-left: 90%;
    
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .arrow{
        width:45px;
        height:40px;
        padding: 3% 5% 5% 5%;
        margin-left: -8%;

    }.arrowStyleRight{
        margin-left: 90%;
    
    }
}

@media (max-width: 768px) {
    .arrow{
        width:30px;
        height:30px;
        padding: 0% 5% 5% 4%;
        margin-left: -15%;

    }.arrowStyleRight{
        margin-left: 90%;
    
    }
}

@media (max-width: 468px) {
    .arrow{
        width:30px;
        height:30px;
        padding: 0% 5% 5% 4%;
        margin-left: 5%;

    }.arrowStyleRight{
        margin-left: 80%;
    
    }
    
}