.title{
  text-align: justify  ;
  text-align-last: center;
  font-family: "Kalnia", serif;
  font-size: 35px;
  color: black;
  font-weight: light;
  transition: all 0.5s;
  padding: 10px 0 ;

}.title2{
  text-align-last: center;
  font-family: "Kalnia", serif;
  font-size: 25px;
 color: black;
 font-weight: 500;  transition: all 0.5s;
 margin-top: -20px;
 padding: 50px 0 20px 0 ;

}.title3{
  text-align-last: center;
  font-family: "Kalnia", serif;
  font-size: 35px;
 color: rgb(255, 255, 255);
 font-weight: 500;  transition: all 0.5s;
 padding: 70px 0 ;

}
  @media (min-width: 992px) and (max-width: 1200px) {
    .title{
      font-size: 30px;
    }
    .title2, .title3{
      font-size: 30px;

    }
  }
  @media (min-width: 768px) and (max-width: 992px) {
        .title{
          font-size: 25px;
                  }          .title2, .title3{
            font-size: 25px;

        }
  }
  @media (max-width: 768px) {
        .title{
          font-size: 20px;
        }
        .title2, .title3{
          font-size: 20px;
          padding: 10px;
        }
  }
  