.paragraph1{
  text-align: justify  ;
  text-align-last: center;
  font-family: "Montserrat";
  font-size: 20px;
  color: black;  transition: all 0.5s;

}.paragraph2{
  text-align: center;
 font-family: "Montserrat";
 font-size: 20px;
 color: black;  transition: all 0.5s;
}.paragraph3{ font-family: "Montserrat";
 font-size: 20px;
 color: black;  transition: all 0.5s;

}.paragraph4{font-family: "Montserrat";
 font-size: 20px;
 color: white;  transition: all 0.5s;

}
  @media (min-width: 768px) and (max-width: 992px) {
        .paragraph1, .paragraph2, .paragraph3, .paragraph4{
          font-size: 15px;
        }
  }
  @media (max-width: 768px) {
        .paragraph1, .paragraph2, .paragraph3, .paragraph4{
          font-size: 12px;
        }
  }
  