.navButton{
  float: "left";
  border: 0px; 
  background: #ffffff00;
  margin-left: 100%;
  margin-top: 40px;    


} 


.bar1, .bar2, .bar3 {
  width: 30px;
  height: 3px;
  background-color: white;
  margin: 6px 0;
  transition: 0.4s;
}

.navButton:hover .bar1,.navButton:hover .bar2,.navButton:hover .bar3 {
  background-color: #2e614e;
}
.change1 .bar1 {
  transform: translate(0, 5px) rotate(-45deg);

}

.change .bar1 {
    transform: translate(0, 15px) rotate(-45deg);
}
.change .bar2 {opacity: 0;}

.change .bar3 {
  transform: translate(0, -3px) rotate(45deg);
}




.navUl{
  color: #fff;
  text-decoration: inherit;
  font-family: "Montserrat";
  font-weight: 450;
   text-align: center;
  list-style: none;
  flex-flow: row nowrap;
  margin-left: -10%;
}.mobileNavUl{
  color: #fff;
  text-decoration: inherit;
  font-family: "Montserrat";
  font-weight: 450;
  margin-top: 20px;    
  text-align: center;
  list-style: none;
  flex-flow: row nowrap;
  margin-left: -10%;
}

.navbarHeader {
  color: #fff;
  text-decoration: inherit;
  font-family: "Montserrat";
  margin: 0.9vw;
}
.navbarHeader:hover {
  color: #60cda5;
}

@media (min-width: 1200px) {
  .mobileNavUl {
    display: flex;
    font-size: 15px;
  }
  .navButton{display: none;}  .navUl {display: none;}

}

@media (min-width: 1005px) and (max-width: 1200px) {
  .mobileNavUl {
    display: flex;
    font-size: 13px;
  }
  .navButton{display: none;}  .navUl {display: none;}

}

@media (min-width: 768px) and (max-width: 1005px) {
  .mobileNavUl{display: none;}
  .navUl {display: none;}
    li{margin: 1vw; transition: all 0.5s;}

}

@media (max-width: 768px) {
  .mobileNavUl{display: none;}
  .navUl {display: none;}
    li{margin: 1vw; transition: all 0.5s;}

}
